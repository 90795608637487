<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import QRCode from "qrcode";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: true,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.clouds.payment.text");
    this.items = [
      {
        text: this.$t("menuitems.clouds.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.clouds.payment.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('global.notice.type.notify')
  },
  methods: {
    getmessagelist() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getnotifymessage",
            page: that.page,
            limit: that.limit,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.messagelist = response.data.list;
          that.messagetotal = response.data.count * 1;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getpaymentauth() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getpaymentauthlink",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.showqrcode(response.data.uri);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async showqrcode(uri) {
      const canvas = this.$refs.canvas;
      const options = { width: 256, height: 256 };
      const dataUrl = await QRCode.toDataURL(uri, options);
      canvas.width = options.width;
      canvas.height = options.height;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0, options.width, options.height);
        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.qrCode = reader.result;
          };
          reader.readAsDataURL(blob);
        });
      };
      img.src = dataUrl;

      // console.log(this.$refs.canvas)
      this.$alert(
        '<img width="100%" src="' + img.src + '">',
        this.$t("device.button.paymentqrcode"),
        {
          confirmButtonText: this.$t("global.button.ok"),
          dangerouslyUseHTMLString: true,
        }
      );
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <canvas style="display: none" ref="canvas"></canvas>
            <p>
              本服务由AICOPAY提供，关于企业及个人信息请参照<a href="https://aicopay.com/privacy.shtml" target="_blank">《AICOPAY 用户隐私政策》</a>与<a href="https://aicopay.com/terms.shtml" target="_blank">《AICOPAY用户服务协议》</a>
            </p>
            <p>
              获取支付服务授权需要您通过https://admin.aicoiot.beer/或https://aicopay.com申请或配置第三方支付(微信、支付宝等)之后授权本后台使用您配置的支付渠道及个人资料。
            </p>
            <p>本功能不会泄露您的个人信息与隐私信息。</p>
            <el-button type="primary" @click="getpaymentauth">{{
              $t("menuitems.clouds.payment.auth")
            }}</el-button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>